// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  Typography,
  InputAdornment,
} from "@mui/material";
// hooks
import useCountdown from "hooks/useCountdown";
// components
import Page from "./Page";
import InputStyle from "./InputStyle";
// assets
import { ComingSoonIllustration } from "assets";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  paddingTop: theme?.spacing(15),
  paddingBottom: theme?.spacing(10),
}));

const CountdownStyle = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const SeparatorStyle = styled(Typography)(({ theme }) => ({
  margin: theme?.spacing(0, 1),
  [theme.breakpoints.up("sm")]: {
    margin: theme?.spacing(0, 2.5),
  },
}));

// ----------------------------------------------------------------------

export default function ComingSoon() {
  const countdown = useCountdown(new Date("07/07/2022 21:30"));

  return (
    <Page title="Coming Soon" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <Typography variant="h3" paragraph>
              Coming Soon!
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              We are currently working hard on this page!
            </Typography>

            <ComingSoonIllustration sx={{ my: 10, height: 240 }} />

            <CountdownStyle>
              <div>
                <Typography variant="h2">{countdown.days}</Typography>
                <Typography sx={{ color: "text.secondary" }}>Days</Typography>
              </div>

              <SeparatorStyle variant="h2">:</SeparatorStyle>

              <div>
                <Typography variant="h2">{countdown.hours}</Typography>
                <Typography sx={{ color: "text.secondary" }}>Hours</Typography>
              </div>

              <SeparatorStyle variant="h2">:</SeparatorStyle>

              <div>
                <Typography variant="h2">{countdown.minutes}</Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Minutes
                </Typography>
              </div>

              <SeparatorStyle variant="h2">:</SeparatorStyle>

              <div>
                <Typography variant="h2">{countdown.seconds}</Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Seconds
                </Typography>
              </div>
            </CountdownStyle>

            <InputStyle
              fullWidth
              placeholder="Enter your email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" size="large">
                      Notify Me
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{ my: 5, "& .MuiOutlinedInput-root": { pr: 0.5 } }}
            />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
