// component
import Iconify from "components/Iconify";
// ----------------------------------------------------------------------
import useAuth from "hooks/useAuth";
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default function NavConfig() {
  const { user, accessToken } = useAuth();
  const loggedUser = user;

  return [
    loggedUser?.role.permissions.includes("canViewDashboard") && {
      title: "Dashboard",
      path: "/dashboard/app",
      icon: getIcon("eva:pie-chart-2-fill"),
    },

    loggedUser?.role.permissions.includes("canViewCalendar") && {
      title: "Events",
      path: "/admin/calendar",
      icon: getIcon("eva:calendar-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewCalendar") && {
          title: "Calendar 1",
          path: "/admin/calendar/calendar",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewCalendar") && {
          title: "Calendar 2",
          path: "/admin/calendar/calendar2",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewOutMembers") && {
          title: "Out Members",
          path: "/admin/calendar/outMembers",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewReceipts") && {
      title: "Receipts",
      path: "/admin/receipts",
      icon: getIcon("eva:file-text-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewRbooks") && {
          title: "Receipt Books",
          path: "/admin/receipts/rbooks",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewReceipts") && {
          title: "Receipt Journals",
          path: "/admin/receipts/receipts",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewFmis") && {
          title: "SFMIS",
          path: "/admin/receipts/fmis",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewMdaAgents") && {
          title: "MDA Agents",
          path: "/admin/receipts/mdaAgent",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewReceiptIssues") && {
          title: "Receipt Issues",
          path: "/admin/receipts/receipt_issues/list",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewContract") && {
      title: "Contracts",
      path: "/admin/contracts",
      icon: getIcon("eva:briefcase-outline"),
      children: [
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "Analytics",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/analytics",
        },
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "List",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/list",
        },
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "Rejected Contracts",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/rejected",
        },
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "Archived Contracts",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/archived",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewAudits") && {
      title: "Audit Tracker",
      path: "/admin/audits",
      icon: getIcon("eva:book-open-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewAuditAnalytics") && {
          title: "General Summary",
          path: "/admin/audits/audit-analytics",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewFinancialAudits") && {
          title: "Financial Audits",
          path: "/admin/audits/financial-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewComplianceAudits") && {
          title: "Compliance Audits",
          path: "/admin/audits/compliance-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewForensicAudits") && {
          title: "Forensic Audits",
          path: "/admin/audits/forensic-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewPerformanceAudits") && {
          title: "Performance Audits",
          path: "/admin/audits/performance-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewInformationAudits") && {
          title: "IS Audits",
          path: "/admin/audits/isa",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewSpecialAudits") && {
          title: "Special Audits",
          path: "/admin/audits/special-audits",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewArchive") && {
      title: "Archive",
      icon: getIcon("eva:folder-outline"),
      path: "/admin/archives",
      children: [
        loggedUser?.role.permissions.includes("canViewArchive") && {
          title: "Archive",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archives/archive",
        },
        loggedUser?.role.permissions.includes("canViewFiles") && {
          title: "File",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archives/files",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewSettings") && {
      title: "Settings",
      icon: getIcon("simple-line-icons:settings"),
      path: "/admin/settings",
      children: [
        loggedUser?.role.permissions.includes("canViewMda") && {
          title: "MDA",
          icon: getIcon("material-symbols:prescriptions-outline"),
          path: "/admin/settings/mda",
        },
        loggedUser?.role.permissions.includes("canViewDevices") && {
          title: "Devices",
          icon: getIcon("material-symbols:computer-outline"),
          path: "/admin/settings/devices",
        },
        loggedUser?.role.permissions.includes("canViewUsers") && {
          title: "Users",
          icon: getIcon("eva:person-outline"),
          path: "/admin/settings/users",
        },
        loggedUser?.role.permissions.includes("canViewRoles") && {
          title: "Roles and Permissions",
          path: "/admin/settings/roles",
        },
        loggedUser?.role.permissions.includes("canViewSMS") && {
          title: "SMS and Mail Config",
          path: "/admin/settings/sms",
        },
        loggedUser?.role.permissions.includes("canViewSystemInfo") && {
          title: "System Config",
          path: "/admin/settings/systemInfo",
        },
        loggedUser?.role.permissions.includes("canViewLogs") && {
          title: "System Logs",
          path: "/admin/settings/logs",
        },
        loggedUser?.role.permissions.includes("canViewBackups") && {
          title: "Backups",
          path: "/admin/settings/backups",
        },
      ],
    },
    {
      title: "Logout",
      icon: getIcon("simple-line-icons:logout"),
      path: "/auth/logout",
    },
  ];
}
